 <template>
  <div class="box col-md-3 ">
    <div>
      <div>
        <div class="accordion accordion-solid-header" role="tablist">
          <b-card no-body>
            <b-collapse
              id="accordion-2"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="willCallWhen992px">
                <!-- willCallWhen992px so é ativada para alterar a ordem do menu filtro -->
                <!-- Especialidades  -------------------------------------- -->

                <b-form-group label="Especialidades" class="size">
                  <multiselect
                    :value="medicoFiltros.especialidades"
                    @input="atualizarEspecialidadesAction"
                    :options="especialidades"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Especialidades"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarEspecialidades"
                    :loading="carregandoEspecialidades"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <b-form-group label="Estados" class="size">
                  <!-- ESTADOS  -------------------------------------- -->
                  <multiselect
                    :value="medicoFiltros.estados"
                    @input="atualizarEstadosAction"
                    :options="estados"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="true"
                    placeholder="Selecionar estados"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- Municípios  -------------------------------------- -->

                <b-form-group label="Municípios" class="size">
                  <multiselect
                    :value="medicoFiltros.estabMunicipios"
                    @input="atualizarEstabMunicipiosAction"
                    :options="municipios"
                    @search-change="findMunicipios"
                    :loading="carregandoMunicipios"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar municípios"
                    :custom-label="nomeUf"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Digite para pesquisar.</template>
                  </multiselect>
                </b-form-group>

                <!-- Área de Atuação  -------------------------------------- -->

                <b-form-group label="Área de Atuação" class="size">
                  <multiselect
                    :value="medicoFiltros.areaAtuacaos"
                    @input="atualizarAreaAtuacaosAction"
                    :options="areaAtuacaos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Área"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarAreaAtuacaos"
                    :loading="carregandoAreaAtuacaos"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- Decisor  -------------------------------------- -->

                <b-form-group label="Decisor" class="size">
                  <multiselect
                    :value="medicoFiltros.cbos"
                    @input="atualizarCbosAction"
                    :options="cbos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Cargo"
                    label="descricao"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- Data do Registro no CFM -------------------------------------- -->

                <b-form-group label="Data do Registro no CFM" class="size">
                  <b-form-select
                    :value="medicoFiltros.dataRegistro"
                    @input="atualizarDataRegistroAction"
                    :options="dataRegistroOptions"
                  >
                  </b-form-select>
                </b-form-group>

                <!-- Local de atuação -------------------------------------- -->

                <b-form-group label="Local de atuação" class="size">
                  <multiselect
                    :value="medicoFiltros.estabTipos"
                    @input="atualizarEstabTiposAction"
                    :options="estabTipos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Local de atuação"
                    label="descricao"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarEstabTipos"
                    :loading="carregandoEstabTipos"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- Fonte de Dados -------------------------------------- -->

                <b-form-group label="Fonte de Dados" class="size">
                  <multiselect
                    :value="medicoFiltros.sites"
                    @input="atualizarSitesAction"
                    :options="sites"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Origem"
                    label="nome"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarSites"
                    :loading="carregandoSites"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- Situação -------------------------------------- -->

                <b-form-group label="Situação" class="size">
                  <multiselect
                    :value="medicoFiltros.medicoSituacaos"
                    @input=" atualizarmedicoSituacaosAction"
                    :options="medicoSituacaos"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                    placeholder="Selecionar Situação"
                    label="descricao"
                    track-by="id"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                    @open="carregarmedicoSituacaos"
                    :loading="carregandomedicoSituacaos"
                  >
                    <template slot="noOptions">Lista está vazia.</template>
                  </multiselect>
                </b-form-group>

                <!-- CRMs -------------------------------------- -->

                <b-form-group label-for="crmsInput">
                  <template slot="label"
                    >CRM
                    <i
                      class="mdi mdi-information-outline"
                      v-b-tooltip.hover
                      title="Pesquise por lista de CRMs, separando um para cada linha. O formato suportado para CRM é <UF><CRM> Ex: MS0005454"
                    ></i
                  ></template>
                  <b-input-group size="md">
                    <b-form-textarea
                      :value="medicoFiltros.crms"
                      @update="atualizarCRMsAction"
                      id="crmsInput"
                      placeholder="CRM ou lista de CRMs"
                      debounce="500"
                      rows="5"
                      max-rows="10"
                    ></b-form-textarea>
                  </b-input-group>
                </b-form-group>
                <div class="formas-de-contato center-content">
                  <div class="formas-de-contato">
                    <label for="formasContato" class="size">Formas de Contato</label>
                    <b-form-checkbox-group v-model="medicoFiltros.formasContato"
                    @input="atualizarFormasContatoAction" class="mb-3">
                      <b-form-checkbox value="email" class="d-inline-block">Email</b-form-checkbox>
                      <b-form-checkbox value="telefone" class="d-inline-block">Telefone</b-form-checkbox>
                      <b-form-checkbox value="celular" class="d-inline-block">Celular</b-form-checkbox>
                      <b-form-checkbox value="endereco" class="d-inline-block">Endereço</b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </div>

                <div class="btn-remove">
                  <b-button
                    block
                    variant="outline-primary"
                    @click="limparRegistro"
                    >Remover Filtros</b-button
                  >
                </div>
              </b-card-body>
              <div class="btn-remove-mobile">
                <b-button
                  block
                  variant="outline-primary"
                  @click="limparRegistro"
                  >Remover Filtros</b-button
                >
              </div>
            </b-collapse>
          </b-card>
        </div>
        <!-- <div class="mb-3">
          <b-button block variant="outline-primary" @click="limparRegistro"
            >Remover Filtros</b-button
          >
        </div> -->
      </div>
    </div>
  </div>
</template>



<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import AreaAtuacaoService from "@/services/doktor/areaatuacao.service";
import EspecialidadeService from "@/services/doktor/especialidade.service";
import EstabelecimentoAtividadeService from "@/services/doktor/estabelecimentoatividade.service";
import EstabelecimentoTipoService from "@/services/doktor/estabelecimentotipo.service";
import EstadoService from "@/services/estado.service";
import MunicipioService from "@/services/municipio.service";
import VinculoService from "@/services/doktor/vinculo.service";
import SiteService from "@/services/doktor/site.service";
import medicosituacaoService from "@/services/doktor/medicosituacao.service";


export default {
  name: "FiltrosAvancados",
  components: {},
  data() {
    return {
      estados: [],
      formasContato: [],
      municipios: [],
      carregandoMunicipios: false,
      especialidades: [],
      carregandoEspecialidades: false,
      areaAtuacaos: [],
      carregandoAreaAtuacaos: false,
      sites: [],
      carregandoSites: false,
      medicoSituacaos: [],
      carregandomedicoSituacaos: false,
      estabTipos: [],
      carregandoEstabTipos: false,
      estabAtividades: [],
      carregandoEstabAtividades: false,
      vinculos: [],
      carregandoVinculos: false,
      cbos: [
        { id: "131205", descricao: "Diretor Geral" },
        { id: "123110", descricao: "Diretor Admnistrativo e Financeiro" },
      ],
      dataRegistroOptions: [
        { value: null, text: "Selecionar" },
        { value: [0, 2], text: "Até 2 anos de Registro" },
        { value: [2, 5], text: "De 2 a 5 anos de Registro" },
        { value: [5, 10], text: "De 5 a 10 anos de Registro" },
        { value: [10], text: "Acima de 10 anos de registro" },
      ],
    };
  },
  props: {},
  computed: {
    ...mapState(["medicoFiltros"]),
    ...mapGetters(["listaEstabEstados"]),
  },
  mounted() {
    EstadoService.getAll().then((response) => {
      this.estados = response.data;
    });
  },
  methods: {
    ...mapActions([
      "atualizarEstadosAction",
      "atualizarEstabAtividadesAction",
      "atualizarEstabTiposAction",
      "atualizarFormasContatoAction",
      "atualizarEspecialidadesAction",
      "atualizarAreaAtuacaosAction",
      "atualizarSitesAction",
      "atualizarmedicoSituacaosAction",
      "atualizarDataRegistroAction",
      "atualizarEstabEstadosAction",
      "atualizarEstabMunicipiosAction",
      "atualizarCbosAction",
      "atualizarVinculosAction",
      "atualizarCRMsAction"
    ]),
    ...mapMutations(["limparRegistro"]),
    atualizarEstados(value) {
      this.atualizarEstabEstadosAction(value);
      if (this.listaEstabEstados.length) {
        this.findMunicipios(null);
      } else {
        this.municipios = [];
      }
    },
    findMunicipios(query) {
      this.carregandoMunicipios = true;
      let params = {
        ufs: this.listaEstabEstados,
        nome: query,
      };
      MunicipioService.getAll(params).then((response) => {
        this.municipios = response.data;
        this.carregandoMunicipios = false;
      });
    },
    nomeUf(municipio) {
      return `${municipio.nome} (${municipio.uf})`;
    },
    carregarEspecialidades() {
      if (this.especialidades.length == 0) {
        this.carregandoEspecialidades = true;
        EspecialidadeService.getAll().then((response) => {
          this.especialidades = response.data;
          this.carregandoEspecialidades = false;
        });
      }
    },
    carregarAreaAtuacaos() {
      if (this.areaAtuacaos.length == 0) {
        this.carregandoAreaAtuacaos = true;
        AreaAtuacaoService.getAll().then((response) => {
          this.areaAtuacaos = response.data;
          this.carregandoAreaAtuacaos = false;
        });
      }
    },
    carregarSites() {
      if (this.sites.length == 0) {
        this.carregandoSites = true;
        SiteService.getAll().then((response) => {
          this.sites = response.data;
          this.carregandoSites = false;
        });
      }
    },
    carregarmedicoSituacaos() {
      if (this.medicoSituacaos.length == 0) {
        this.carregandomedicoSituacaos = true;
        medicosituacaoService.getAll().then((response) => {
          this.medicoSituacaos = response.data;
          this.carregandomedicoSituacaos = false;
        });
      }
    },
    carregarEstabTipos() {
      if (this.estabTipos.length == 0) {
        this.carregandoEstabTipos = true;
        EstabelecimentoTipoService.getAll().then((response) => {
          this.estabTipos = response.data;
          this.carregandoEstabTipos = false;
        });
      }
    },
    carregarEstabAtividades() {
      if (this.estabAtividades.length == 0) {
        this.carregandoEstabAtividades = true;
        EstabelecimentoAtividadeService.getAll().then((response) => {
          this.estabAtividades = response.data;
          this.carregandoEstabAtividades = false;
        });
      }
    },
    carregarVinculos() {
      if (this.vinculos.length == 0) {
        this.carregandoVinculos = true;
        VinculoService.getAll().then((response) => {
          this.vinculos = response.data;
          this.carregandoVinculos = false;
        });
      }
    },
  },
};
</script>


<style scoped lang="scss">
.box {
  min-width: 20%;
  padding-bottom: 14px;
  padding-left: 0;
}
.custom-select {
  color: #adadad;
  font-size: 14px;
}
.btn-remove-mobile {
  display: none;
}

@media (max-width: 992px) {
  .box {
    min-width: 100%;
    padding: 0;
  }
  .willCallWhen992px {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .size {
    width: 100%;
  }
  .btn-remove {
    display: none;
  }
  .btn-remove-mobile {
    display: block;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      max-width: 90%;
    }
  }
}

@media (max-width: 320px) {
  .willCallWhen992px {
    flex-direction: column;
  }
  .btn-remove-mobile {
    max-width: 85%;
  }
  .size {
    width: 80%;
  }
}

.center-content {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  
  
  
}


</style>
